import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITestAnswer, TestAnswerItemsNuska } from "./testAnswer.interface";
import { getState, removeState, setState } from "../../storage";

const initialState: ITestAnswer = {
   data: getState("userTestItems") ?? [],
   data5: getState("userTestItems5") ?? [],
   dataNuska: getState("userTestItemsNuska") ?? [],
   dataTeacher: getState("userTestItemsTeacher") ?? [],
};

export const testAnswerSlice = createSlice({
   name: "testAnswer",
   initialState,
   reducers: {
      setInitialTestAnswerItemsNuska: (state, action: PayloadAction<TestAnswerItemsNuska>) => {
         state.dataNuska.push(action.payload);
         setState("userTestItemsNuska", state.dataNuska);
      },

      setTestAnswerItems: (state, action) => {
         if (state.data.map((x) => x.ForId).includes(action.payload.ForId)) {
            state.data.map((x) => {
               if (x.ForId === action.payload.ForId) {
                  x.Otvet = action.payload.Otvet;
               }
            });
         } else {
            state.data = [...state.data, action.payload];
         }
         setState("userTestItems", state.data);
      },
      setTestAnswerItems5: (state, action) => {
         // state.data5.fiveId = action.payload.fiveId;
         // console.log(action);

         let answerObj = {
            Otvet: action.payload.otvet,
            ForId: action.payload.forId,
         };

         if (state.data5.length !== 0) {
            let testItem = state.data5.find((x) => x.TestId === action.payload.testId)
               ? state.data5.find((x) => x.TestId === action.payload.testId)?.Items
               : null;

            if (testItem) {
               if (testItem.map((x) => x.ForId).includes(action.payload.forId)) {
                  state.data5
                     .find((x) => x.TestId === action.payload.testId)
                     ?.Items.map((x) => {
                        if (x.ForId === action.payload.forId) {
                           x.Otvet = action.payload.otvet;
                        }
                     });
               } else {
                  state.data5.find((x) => x.TestId === action.payload.testId)?.Items.push(answerObj);
                  // state.data5.find(x => x.TestId === action.payload.testId)?.Items = [...state.data5.find(x => x.TestId === action.payload.testId)?.Items, answerObj];
               }
            } else {
               state.data5.push({
                  TestId: action.payload.testId,
                  PanId: action.payload.panId,
                  Items: [answerObj],
               });
            }
         } else {
            state.data5.push({
               TestId: action.payload.testId,
               PanId: action.payload.panId,
               Items: [answerObj],
            });
         }
         setState("userTestItems5", state.data5);
      },
      setTestAnswerItemsNuska: (state, action) => {
         let answerObj = {
            Otvet: action.payload.otvet,
            ForId: action.payload.forId,
         };

         if (state.dataNuska.length !== 0) {
            let testItem = state.dataNuska.find((x) => x.PanId === action.payload.panId)
               ? state.dataNuska.find((x) => x.PanId === action.payload.panId)?.Items
               : null;

            if (testItem) {
               if (testItem.map((x) => x.ForId).includes(action.payload.forId)) {
                  state.dataNuska
                     .find((x) => x.PanId === action.payload.panId)
                     ?.Items.map((x) => {
                        if (x.ForId === action.payload.forId) {
                           x.Otvet = action.payload.otvet;
                        }
                     });
               } else {
                  state.dataNuska.find((x) => x.PanId === action.payload.panId)?.Items.push(answerObj);
                  // state.dataNuska.find(x => x.PanId === action.payload.panId)?.Items = [...state.dataNuska.find(x => x.TestId === action.payload.testId)?.Items, answerObj];
               }
            } else {
               state.dataNuska.push({
                  PanId: action.payload.panId,
                  Items: [answerObj],
               });
            }
         } else {
            state.dataNuska.push({
               PanId: action.payload.panId,
               Items: [answerObj],
            });
         }
         setState("userTestItemsNuska", state.dataNuska);
      },
      setTestAnswerItemsTeacher: (state, action) => {
         if (state.dataTeacher.map((x) => x.ForId).includes(action.payload.ForId)) {
            state.dataTeacher.map((x) => {
               if (x.ForId === action.payload.ForId) {
                  x.Otvet = action.payload.Otvet;
               }
            });
         } else {
            state.dataTeacher = [...state.dataTeacher, action.payload];
         }
         setState("userTestItemsTeacher", state.dataTeacher);
      },

      removeTestAnswerItems: (state) => {
         state.data = [];
         state.data5 = [];
         state.dataNuska = [];
         state.dataTeacher = [];
         removeState("userTestItems");
         removeState("userTestItems5");
         removeState("userTestItemsNuska");
         removeState("userTestItemsTeacher");
      },
   },
});

export const {
   setTestAnswerItems,
   setTestAnswerItems5,
   setTestAnswerItemsNuska,
   setTestAnswerItemsTeacher,
   setInitialTestAnswerItemsNuska,
   removeTestAnswerItems,
} = testAnswerSlice.actions;
export const testAnswerReducer = testAnswerSlice.reducer;
