export const getUserTarifDate = (authData : any) : string => {
   if (authData && authData.testMonth) {
      const date = new Date(authData.testMonth);
      
      const year = date.getFullYear();
      const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const day = date.getDate();
      // const hour = date.getHours();
      // const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
      return `${day}.${month}.${year}`;
   }
   return "";
}