

// get date with format {DD.MM hh:mm}
export const getStrDate = (createDate : Date) : string => {
   const date = new Date(createDate);

   // const year = date.getFullYear();
   const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
   const day = date.getDate();
   const hour = date.getHours();
   const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

   // return `${day}.${month}.${year} ${hour}:${minutes}`;
   return `${day}.${month} ${hour}:${minutes}`;
}


// get date with format {DD.MM.YYYY}
export const getStrDate2 = (createDate : Date) : string => {
   const date = new Date(createDate);

   const year = date.getFullYear();
   const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
   const day = date.getDate();

   return `${day}.${month}.${year}ж`;
}