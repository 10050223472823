const authScriptUrls = [
   "/assets/js/jquery.min.js",
   "/assets/vendors/bootstrap/js/bootstrap.min.js",
   "/assets/vendors/bootstrap/js/popper.min.js",
   "/assets/vendors/bootstrap-select/bootstrap-select.min.js",
   "/assets/vendors/bootstrap-touchspin/jquery.bootstrap-touchspin.js",
   "/assets/vendors/magnific-popup/magnific-popup.js",
   "/assets/vendors/counter/waypoints-min.js",
   "/assets/vendors/counter/counterup.min.js",
   "/assets/vendors/imagesloaded/imagesloaded.js",
   "/assets/vendors/masonry/masonry.js",
   "/assets/vendors/masonry/filter.js",
   "/assets/vendors/owl-carousel/owl.carousel.js",
   "/assets/js/functions.js",
   "/assets/js/contact.js",
];
const contentScriptUrls = [
   "/assets/js/jquery.min.js",
   "/assets/vendors/bootstrap/js/bootstrap.min.js",
   "/assets/vendors/counter/waypoints-min.js",
   "/assets/vendors/counter/counterup.min.js",
   "/assets/vendors/scroll/scrollbar.min.js",
   "/assets/vendors/calendar/moment.min.js",
   "/assets/vendors/bootstrap/js/popper.min.js",
   "/assets/js/admin.js",
   "/assets/js/selectScript.js",
   // 'assets/vendors/calendar/fullcalendar.js'
];

const playerScriptUrls = ["https://cdn.plyr.io/3.5.6/plyr.js", "/assets/js/playerScript.js"];
const mathScripts = [
   "https://polyfill.io/v3/polyfill.min.js?features=es6",
   "https://cdn.jsdelivr.net/npm/mathjax@3/es5/mml-chtml.js",
];

const remScript = () => {
   const els = document.getElementsByTagName("script");
   [...els].map((el) => !el.src.includes("bundle.js") && el.remove());
};

const importScript = (url: string) => {
   remScript();

   const script = document.createElement("script");
   script.src = url;
   script.async = true;

   if (url === "https://cdn.jsdelivr.net/npm/mathjax@3/es5/mml-chtml.js") {
      script.id = "MathJax-script";
   }
   document.body.appendChild(script);

   return () => document.body.removeChild(script);
};

export const importAuthScripts = () => authScriptUrls.map((url) => importScript(url));
export const importContentScripts = () => contentScriptUrls.map((url) => importScript(url));
export const importPlayerScripts = () => playerScriptUrls.map((url) => importScript(url));
export const importMathScripts = () => mathScripts.map((url) => importScript(url));

// <?xml version="1.0" encoding="UTF-8"?>
// <configuration>
//     <system.webServer>
// 		<rewrite>
// 		  <rules>
// 			<rule name="ReactRouter Routes" stopProcessing="true">
// 			  <match url=".*" />
// 			  <conditions logicalGrouping="MatchAll">
// 				<add input="{REQUEST_FILENAME}" matchType="IsFile" negate="true" />
// 			  </conditions>
// 			  <action type="Rewrite" url="index.html" />
// 			</rule>

// 		  </rules>
// 		</rewrite>
//     </system.webServer>
// </configuration>
